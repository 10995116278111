import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiWalletService } from '@api';
import { TContact, TCreateContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    handleBack: () => void;
    editableContact?: TContact;
}

const Contact: FC<IProps> = ({ handleBack, editableContact }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.manage_contacts'
    });
    const form = useForm({});
    const {
        requestSuccessLocale,
        requestErrorLocale,
        labelLocale,
        validationLocale,
        submitLocale
    } = useLocales();
    const [isEmail, setIsEmail] = useState(true);

    useEffect(() => {
        if (editableContact) {
            form.reset(editableContact);
        }
    }, []);

    const { handleRequest: handleCreateContact, isLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const data: TCreateContact = {
                firstName: values?.firstName,
                lastName: values?.lastName,
                phone: undefined,
                email: undefined
            };
            if (isEmail) {
                data.email = values?.email;
            } else {
                data.phone = values?.phone;
            }
            return apiWalletService.createContact(data);
        },
        onSuccess: () => {
            requestSuccessLocale('create_contact');
            handleBack();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUpdateContact, isLoading: isUpdateContactLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                const data: TCreateContact = {
                    firstName: values?.firstName,
                    lastName: values?.lastName
                };
                return apiWalletService.updateContact(editableContact?.id || '', data);
            },
            onSuccess: () => {
                requestSuccessLocale('create_contact');
                handleBack();
            },
            onError: requestErrorLocale
        });

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                if (editableContact) {
                    handleUpdateContact(undefined);
                } else {
                    handleCreateContact(undefined);
                }
            }
        });
    };

    return (
        <FormProvider {...form}>
            <motion.div {...pageAnimation} className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t('add_contact_description')}
                </Typography>
                <div className={styles.form_row}>
                    <FormField
                        name="firstName"
                        renderComponent={(props) => (
                            <Input label={labelLocale('first_name')} full {...props} />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('firstName') }
                        }}
                    />
                    <FormField
                        name="lastName"
                        renderComponent={(props) => (
                            <Input label={labelLocale('last_name')} full {...props} />
                        )}
                        rules={{ required: { value: true, message: validationLocale('lastName') } }}
                    />
                </div>
            </motion.div>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.ddr_info}>
                    {t('notify_1')} <span>{t('notify_2')}</span> {t('notify_3')}
                </Typography>
                <div className={styles.form_row}>
                    <Radio
                        className={styles.radio}
                        small
                        disabled={!!editableContact}
                        checked={isEmail}
                        onClick={() => setIsEmail(true)}
                    />
                    <FormField
                        name="email"
                        renderComponent={(props) => (
                            <Input
                                disabled={!isEmail}
                                label={labelLocale('email')}
                                full
                                readOnly={!!editableContact}
                                {...props}
                            />
                        )}
                        rules={{
                            required: {
                                value: isEmail && !editableContact,
                                message: validationLocale('email')
                            }
                        }}
                    />
                    <FormField
                        name="confirmEmail"
                        renderComponent={(props) => (
                            <Input
                                disabled={!isEmail}
                                label={labelLocale('confirm_email')}
                                full
                                readOnly={!!editableContact}
                                {...props}
                            />
                        )}
                        rules={{
                            required: {
                                value: isEmail && !editableContact,
                                message: validationLocale('emailConfirm')
                            },
                            validate: (val: string) => {
                                if (!!editableContact) {
                                    return undefined;
                                }
                                return (
                                    val === form.getValues('email') ||
                                    validationLocale('emailConfirm')
                                );
                            }
                        }}
                    />
                </div>
                <div className={styles.form_row}>
                    <Radio
                        className={styles.radio}
                        small
                        disabled={!!editableContact}
                        checked={!isEmail}
                        onClick={() => setIsEmail(false)}
                    />
                    <FormField
                        name="phone"
                        renderComponent={(props) => (
                            <Input
                                disabled={isEmail}
                                label={labelLocale('enter_contact_phone')}
                                full
                                readOnly={!!editableContact}
                                {...props}
                                mask="(999) 999 99 99"
                            />
                        )}
                        rules={{
                            required: {
                                value: !isEmail && !editableContact,
                                message: validationLocale('phone')
                            }
                        }}
                    />
                    <FormField
                        name="confirmPhone"
                        renderComponent={(props) => (
                            <Input
                                disabled={isEmail}
                                label={labelLocale('enter_contact_confirm_phone')}
                                full
                                readOnly={!!editableContact}
                                {...props}
                                mask="(999) 999 99 99"
                            />
                        )}
                        rules={{
                            required: {
                                value: !isEmail && !editableContact,
                                message: validationLocale('phone')
                            },
                            validate: (val: string) => {
                                if (!!editableContact) {
                                    return undefined;
                                }
                                return (
                                    val === form.getValues('phone') ||
                                    validationLocale('verify_contact_form')
                                );
                            }
                        }}
                    />
                </div>
            </div>
            <Button
                variant="primary"
                size="normal"
                onClick={onSubmit}
                isLoading={isLoading || isUpdateContactLoading}>
                {t(!!editableContact ? 'update_contact_submit' : 'add_contact_submit')}
            </Button>
            <Button leftIcon="arrow_left" onClick={handleBack}>
                {submitLocale('back')}
            </Button>
        </FormProvider>
    );
};

export default Contact;
