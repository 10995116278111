import React, { FC } from 'react';
import illustration from '@svg/illustrations/e_transfer_send_success.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    onRepeat: () => void;
}

const Success: FC<IProps> = ({ onRepeat }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.send_money.success'
    });
    const { submitLocale } = useLocales();
    const navigate = useNavigate();
    return (
        <motion.div {...pageAnimation} className={styles.success}>
            <img src={illustration} alt="illustration" />
            <p className={styles.title}>{t('title')}</p>
            <Typography variant="body1">{t('success_description')}</Typography>
            <div className={styles.actions}>
                <Button
                    variant="outlined-dark"
                    size="large"
                    onClick={() => navigate(routes.dashboard)}>
                    {submitLocale('done')}
                </Button>
                <Button variant="primary" size="large" onClick={onRepeat}>
                    {submitLocale('send_another')}
                </Button>
            </div>
        </motion.div>
    );
};

export default Success;
