import { Country } from 'country-state-city';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';
import { ECardStatus, ECardType } from '@xeppt/xeppt-sdk/types/card';
import { ETransactionAction, ETransactionStatus } from '@xeppt/xeppt-sdk/types/transaction';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { NavigateFunction } from 'react-router-dom';
import { EUserStatus, TNotification, EEventName, ETransactionMethod } from '@xeppt/xeppt-sdk/types';
import { routes } from '@const/routes';
import React from 'react';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';

export const getFirstLetter = (string?: string) => {
    if (!string) return '';
    return string.charAt(0);
};

export const hideCardNumber = (
    value: number | string,
    type?: 'full' | 'partial',
    variant?: 'dots' | 'stars'
) => {
    const separator = variant === 'dots' ? '••••' : '****';
    const string = String(value);
    if (type === 'full') {
        return `${separator} ${string.slice(12)}`;
    } else {
        return `${string.slice(0, 4)} ${separator} ${separator} ${string.slice(12)}`;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hidePhoneNumber = (value: string | number) => {
    const str = String(value);
    return (
        str
            .substring(0, str.length - 4)
            .split('')
            .map(() => '•')
            .join('') + str.substring(str.length - 4, str.length)
    );
};

export const joiningArrayWords = (array: string[]) => {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        const joinedFormats = array.slice(0, -1).join(', ');
        return `${joinedFormats} or ${array[array.length - 1]}`;
    }
};

export const getBirthDisabledDates = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return maxDate;
};

export const getDisablePast = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear());

    return maxDate;
};

export const prettifyGoogleAddress = ({ address_components }: any) => {
    let streetName = '';
    let streetNumber = '';
    const address = {
        country: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        region: ''
    };

    for (const component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                streetNumber = component.long_name;
                break;
            }
            case 'route': {
                streetName = component.long_name;
                break;
            }
            case 'locality': {
                address['city'] = component.long_name;
                break;
            }
            case 'country': {
                address['country'] = component.short_name;
                break;
            }
            case 'postal_code': {
                address['zipCode'] = component.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                address['region'] += component.long_name;
                break;
            }
            default:
                break;
        }
    }
    if (
        address.country === '' ||
        address.city === '' ||
        streetName === '' ||
        streetNumber === '' ||
        address.zipCode === '' ||
        address.region === ''
    ) {
        return undefined;
    } else {
        return {
            ...address,
            address1: streetNumber + ' ' + streetName
        };
    }
};

export const getAddressFormName = (type: EAddressType) => {
    if (type === EAddressType.BUSINESS) {
        return 'businessAddress';
    } else if (type === EAddressType.REGISTRATION) {
        return 'registrationAddress';
    } else if (type === EAddressType.BILLING) {
        return 'billingAddress';
    } else {
        return 'tradingAddress';
    }
};

export const getAddressName = (type: EAddressType, localization: (val: string) => string) => {
    if (type === EAddressType.BUSINESS) {
        return localization('business');
    } else if (type === EAddressType.REGISTRATION) {
        return localization('registration');
    } else if (type === EAddressType.BILLING) {
        return localization('billing');
    } else {
        return localization('trading');
    }
};

export const prettifyCardStatus = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'active';
        case ECardStatus.TEMPORARY_CLOSED:
            return 'inactive';
        case ECardStatus.WAITING_FOR_ACTIVATION:
            return 'waiting for activation';
        case ECardStatus.LOST:
            return 'lost';
        case ECardStatus.STOLEN:
            return 'stolen';
        case ECardStatus.OTHER:
            return 'other';
        case ECardStatus.FRAUD:
            return 'fraud';
        case ECardStatus.CUSTOMER_REQUEST:
            return 'requested';
        case ECardStatus.CLOSED:
            return 'closed';
        case ECardStatus.LEGAL_CLOSED:
            return 'legal closed';
        case ECardStatus.FREEZED:
            return 'frozen';
    }
};

export const prettifyCardType = (status?: ECardType) => {
    switch (status) {
        case ECardType.PHYSICAL:
            return 'physical';
        case ECardType.VIRTUAL:
            return 'virtual';
        case ECardType.VIRTUAL_SUPPLEMENTARY:
            return 'virtual Supplementary';
        case ECardType.PHYSICAL_SUPPLEMENTARY:
            return 'physical Supplementary';
        default:
            return 'physical';
    }
};

export const getTransactionAction = (action: ETransactionAction, t: any) => {
    switch (action) {
        case ETransactionAction.SEND_MONEY:
            return t('send_money');
        case ETransactionAction.RECEIVE_MONEY:
            return t('receive_money');
        case ETransactionAction.LOAD_BALANCE:
            return t('load_balance');
        case ETransactionAction.TRANSACTION_FEE:
            return t('transaction_fee');
        case ETransactionAction.BILL_PAYMENT:
            return t('bill_payment');
        case ETransactionAction.REQUEST_CARD:
            return t('request_card');
    }
};

export const getTagVariant = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'success';
        case ECardStatus.OTHER:
        case ECardStatus.WAITING_FOR_ACTIVATION:
        case ECardStatus.CUSTOMER_REQUEST:
            return 'warning';
        case ECardStatus.TEMPORARY_CLOSED:
        case ECardStatus.LOST:
        case ECardStatus.STOLEN:
        case ECardStatus.FRAUD:
        case ECardStatus.CLOSED:
        case ECardStatus.LEGAL_CLOSED:
        case ECardStatus.FREEZED:
            return 'error';
    }
};

export const getTransactionStatus = (status?: ETransactionStatus) => {
    switch (status) {
        case ETransactionStatus.APPROVED:
            return 'success';
        case ETransactionStatus.PENDING:
            return 'warning';
        case ETransactionStatus.DECLINED:
        case ETransactionStatus.CANCELED:
            return 'error';
        default:
            return 'success';
    }
};

export const fileToBase64 = (file: File) => {
    const promise = async () =>
        new Promise<string>((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader?.result as string);
            };
            reader.onerror = (error) => {
                rej(`Error reading file: ${error}`);
                console.error('Error reading file: ', error);
            };
        });
    return promise();
};

export const base64ToFile = (base64String: string, fileName: string, mimeType: string) => {
    const byteString = atob(base64String.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
};

export const downloadFileFromBlob = (data: any, name: string) => {
    const a = document.createElement('a');
    a.download = name;
    a.href = URL.createObjectURL(data);
    a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export const formatDataToXlsx = (data: any) => {
    const keys: any[] = [];
    data.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
            if (!keys.includes(key)) {
                keys.push(camelize(key));
            }
        });
    });
    const widthArray = keys.map((item) => {
        return {
            wch: data.reduce((w: any, r: any) => Math.max(w, r[item]?.length || 0), 13)
        };
    });
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheethtml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = widthArray;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
};

export const camelize = (str: string) => {
    return str
        .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const prettifyAmount = (amount: string) => {
    const parts = amount.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

export function getDefaultPhoneCode(tz?: string) {
    const countries = Country.getAllCountries();
    const localTimeZone = tz || moment.tz.guess();
    const defaultCountry = Country.getCountryByCode('CA');

    const localCountry = countries.find((country) =>
        country?.timezones?.find((data) => data.zoneName === localTimeZone)
    );

    return (localCountry?.phonecode as string) || (defaultCountry?.phonecode as string);
}

export function getStartAndEndOfMonth(year: number, month: number) {
    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);

    return { startOfMonth, endOfMonth };
}

export const getNotificationString = (
    notification: TNotification,
    shuftiProLocale: (val: string) => string
) => {
    switch (notification.event) {
        case EEventName.UserVerified:
            return (
                notification.payload?.codes &&
                notification.payload?.codes
                    //@ts-ignore
                    ?.split(' ')
                    .map((item: string) => <div key={item}>⦿ {shuftiProLocale(item)}</div>)
            );
        case EEventName.EftAccountLinked:
            return notification.payload?.account_name;
        case EEventName.PaymentReceived:
            return `${notification.payload?.amount} CAD ${notification.payload?.from} (${
                notification.payload?.method &&
                enumTranslate[
                    notification.payload?.method === ETransactionMethod.EFT
                        ? 'to_bank'
                        : (notification.payload?.method as ETransactionMethod)
                ][(localStorage.getItem('language') as 'en' | 'fr') || 'en']
            })`;
        case EEventName.PaymentStatusChanged:
            return (
                notification.payload?.status &&
                ETransactionStatusTranslate[notification.payload?.status as ETransactionStatus][
                    (localStorage.getItem('language') as 'en' | 'fr') || 'en'
                ]
            );
        case EEventName.CardStatusChanged:
            return capitalizeFirstLetter(
                prettifyCardStatus(notification.payload?.status as ECardStatus)
            );
        case EEventName.CardBalanceLoaded:
            return `${notification.payload?.amount} CAD`;
        case EEventName.SchedulePaymentProcessed:
            return `${notification.payload?.amount} CAD (${notification.payload?.payee})`;
    }
};

export const getNotificationAction = (notification: TNotification, navigate: NavigateFunction) => {
    switch (notification.event) {
        case EEventName.UserVerified:
            return {
                status: EUserStatus,
                codes: ''
            };
        case EEventName.EftAccountLinked:
            return (
                notification.payload?.id &&
                navigate(`${routes.manage_accounts}?id=${notification.payload?.id}`)
            );
        case EEventName.PaymentReceived:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.PaymentStatusChanged:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.CardCreated:
            return (
                notification.payload?.card_id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.card_id}`)
            );
        case EEventName.CardPinChanged:
            return (
                notification.payload?.card_id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.card_id}`)
            );
        case EEventName.CardLimitsUpdated:
            return (
                notification.payload?.card_id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.card_id}`)
            );
        case EEventName.CardStatusChanged:
            return (
                notification.payload?.card_id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.card_id}`)
            );
        case EEventName.CardBalanceLoaded:
            return (
                notification.payload?.card_id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.card_id}`)
            );
        case EEventName.SchedulePaymentProcessed:
            return notification.payload?.id && navigate(`${routes.pay_bills_schedule}`);
    }
};
