import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EActiveETransfer } from '@enum';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import SendMoneySection from '@sections/e_transfer/send_money';
import RequestMoneySection from '@sections/e_transfer/request_money';
import ManageAutodepositSection from '@sections/e_transfer/manage_autodeposit';
import ManageContactsSection from '@sections/e_transfer/manage_contacts';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useUserContext } from '@hooks/context/useUserContext';

const cx = classNames.bind(styles);

interface IProps {
    activeTab?: EActiveETransfer;
}

const ETransferLayout: FC<IProps> = ({ activeTab }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('e_transfer');
    const { verifiedAction } = useVerificationGuard();
    const [currentTab, setCurrentTab] = useState<EActiveETransfer>();
    const { user } = useUserContext();

    const contentStyle = useMemo(
        () => cx([styles.content_wrapper, { isOpen: !!currentTab }]),
        [currentTab]
    );
    const sidebarStyle = useMemo(
        () => cx([styles.sidebar, { isOpen: !!currentTab }]),
        [currentTab]
    );

    const renderTab = () => {
        switch (activeTab) {
            case EActiveETransfer.SEND:
                return <SendMoneySection />;
            case EActiveETransfer.REQUEST:
                return <RequestMoneySection />;
            case EActiveETransfer.CONTACTS:
                return <ManageContactsSection />;
            case EActiveETransfer.AUTODEPOSIT:
                return <ManageAutodepositSection />;
        }
    };

    useEffect(() => {
        if (activeTab) {
            verifiedAction(
                () => {
                    setCurrentTab(activeTab);
                },
                () => {
                    setCurrentTab(undefined);
                    navigate(routes.e_transfer);
                }
            );
        } else {
            setCurrentTab(undefined);
        }
    }, [activeTab, user]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={contentStyle}>
                <div className={styles.content}>{renderTab()}</div>
            </div>
            <div className={sidebarStyle}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.divider} />
                <div
                    className={cx([styles.tab, { isActive: currentTab === EActiveETransfer.SEND }])}
                    onClick={() => navigate(routes.e_transfer_send)}>
                    <Button leftIcon="send" variant="icon" />
                    {t('send_money')}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.REQUEST }
                    ])}
                    onClick={() => navigate(routes.e_transfer_request)}>
                    <Button leftIcon="dollar" variant="icon" />
                    {t('request_money')}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.CONTACTS }
                    ])}
                    onClick={() => navigate(routes.e_transfer_contacts)}>
                    <Button leftIcon="person" variant="icon" />
                    {t('manage_contacts')}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.AUTODEPOSIT }
                    ])}
                    onClick={() => navigate(routes.e_transfer_autodeposit)}>
                    <Button leftIcon="yen" variant="icon" />
                    {t('manage_autodeposit')}
                </div>
            </div>
        </motion.div>
    );
};

export default ETransferLayout;
