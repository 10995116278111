import { TProgressItem } from '@components/progress_bar';

export const signupPersonalProgressSteps: (TProgressItem | undefined)[] = [
    {
        id: '1',
        title: 'Account'
    },
    {
        id: '2',
        title: 'Email'
    },
    {
        id: '3',
        title: 'Phone'
    },
    {
        id: '4',
        title: 'Details'
    },
    {
        id: '5',
        title: 'Address'
    },
    {
        id: '6',
        title: 'Verification'
    },
    {
        id: '7',
        title: ' Password'
    },
    undefined
];

export const signupBusinessProgressSteps: (TProgressItem | undefined)[] = [
    {
        id: '1',
        title: 'Account'
    },
    {
        id: '2',
        title: 'Email'
    },
    {
        id: '3',
        title: 'Phone'
    },
    {
        id: '4',
        title: 'Details'
    },
    {
        id: '5',
        title: 'Business'
    },
    {
        id: '6',
        title: 'Addresses'
    },
    {
        id: '7',
        title: 'Verification'
    },
    {
        id: '8',
        title: ' Password'
    },
    undefined
];

export const eTransferSendMoneyProgressSteps: (TProgressItem | undefined)[] = [
    {
        id: '1',
        title: 'Enter Information'
    },
    {
        id: '2',
        title: 'Verification'
    },
    {
        id: '3',
        title: 'Send Money'
    },
    undefined
];

export const eTransferRequestMoneyProgressSteps: (TProgressItem | undefined)[] = [
    {
        id: '1',
        title: 'Enter Information'
    },
    {
        id: '2',
        title: 'Verification'
    },
    {
        id: '3',
        title: 'Request Money'
    },
    undefined
];

export const payBillsPayBillsProgressSteps: (TProgressItem | undefined)[] = [
    {
        id: '1',
        title: 'Select payee'
    },
    {
        id: '2',
        title: 'Payment Details'
    },
    {
        id: '3',
        title: 'Verification'
    },
    {
        id: '4',
        title: 'Confirm to Pay'
    },
    undefined
];
