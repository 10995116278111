import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import Link from '@components/common/link';
import useSignupContext from '@hooks/context/useSignupContext';
import { routes } from '@const/routes';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const EmailSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.email'
    });
    const { labelLocale, submitLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const { trigger } = useFormContext();

    const onSubmit = () => {
        trigger().then((isValid) => isValid && nextStep());
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('already')} <Link to={routes.signin}>{t('login')}</Link>
            </Typography>
            <div className={styles.form}>
                <Typography variant="body2">{t('enter_email')}</Typography>
                <FormField
                    name="email"
                    renderComponent={(props) => (
                        <Input {...props} full required label={labelLocale('email')} />
                    )}
                />
                <Button onClick={onSubmit} size="normal" variant="primary">
                    {submitLocale('next')}
                </Button>
                <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                    {submitLocale('back')}
                </Button>
            </div>
            <Typography lh="normal">
                {t('by_registration')} <Link to={routes.terms_of_service}>{t('term')}</Link>{' '}
                {t('and')} <Link to={routes.privacy_policy}>{t('privacy')}</Link>
            </Typography>
        </motion.div>
    );
};

export default EmailSection;
