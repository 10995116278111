import React, { FC, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    value?: string | number;
    onChange?: (value: string | number) => void;
    onChangeSearch: (value: string) => void;
    search: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    name?: string;
    error?: boolean;
    items: {
        value: string | number;
        label: string;
    }[];
    full?: boolean;
    disabled?: boolean;
}

const cx = classNames.bind(styles);

const Select: FC<IProps> = ({
    value = '',
    placeholder,
    disabled,
    full,
    label,
    items,
    error,
    onChange,
    onChangeSearch,
    search,
    name,
    helperText
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string | number) => {
        onChange && onChange(value);
        onChangeSearch('');
        setIsOpen(false);
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={ref}>
            <Input
                rightIcon="search"
                value={search || items.find((item) => item.value === value)?.label}
                placeholder={placeholder}
                label={label}
                full={full}
                name={name}
                className={cx([{ isOpen }])}
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
                disabled={disabled}
                error={error}
                onChange={(val) => onChangeSearch(val)}
                helperText={helperText}
            />
            {items.length > 0 && (
                <div className={cx([styles.dropdown, { isOpen }])}>
                    {items.map((item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            {item.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select;
