import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiRegistrationService } from '@api';
import { TActivateUserPayload } from '@xeppt/xeppt-sdk/types';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { passwordValidation } from '@utils/validation';

const PasswordSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.password'
    });
    const { labelLocale, submitLocale, requestErrorLocale, validationLocale } = useLocales();
    const { nextStep } = useSignupContext();
    const {
        formState: { isValid },
        getValues,
        trigger
    } = useFormContext<TActivateUserPayload>();

    const { handleRequest: handleActivate, isLoading } = useApiMutation({
        method: () => {
            const { code, password, passwordConfirm } = getValues();
            return apiRegistrationService.activateUser({ code, password, passwordConfirm });
        },
        onSuccess: () => nextStep(),
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                handleActivate(undefined);
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('password_1')} <span>{t('password_2')}</span> {t('password_3')}{' '}
                <span>{t('password_4')}</span>
                {t('password_5')} <span>{t('password_6')}</span>
            </Typography>
            <div className={styles.form}>
                <FormField
                    name="code"
                    renderComponent={(props) => (
                        <Input
                            label={labelLocale('enter_code')}
                            {...props}
                            full
                            placeholder="Code"
                            required
                        />
                    )}
                />
                <FormField
                    name="password"
                    renderComponent={({ value, ...props }) => (
                        <Input
                            {...props}
                            label={labelLocale('password')}
                            full
                            value={value}
                            placeholder="••••••••"
                            type="password"
                            required
                            passwordErrors={passwordValidation(value, validationLocale)}
                        />
                    )}
                />
                <FormField
                    name="passwordConfirm"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('confirm_password')}
                            full
                            placeholder="••••••••"
                            type="password"
                            required
                        />
                    )}
                />
                <Button
                    disabled={!isValid}
                    className={styles.next}
                    onClick={onSubmit}
                    isLoading={isLoading}
                    size="normal"
                    variant="primary">
                    {submitLocale('next')}
                </Button>
            </div>
        </motion.div>
    );
};

export default PasswordSection;
