import React, { FC } from 'react';
import styles from '@sections/e_transfer/manage_contacts/styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import { apiWalletService } from '@api';
import { TContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onAdd: () => void;
    refetchContacts: () => void;
    handleEdit: (data: TContact) => void;
    contactList: TContact[];
}

const ContactsTable: FC<IProps> = ({ onAdd, contactList, refetchContacts, handleEdit }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'e_transfer.manage_contacts'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const navigate = useNavigate();
    const { onClose, onOpen, handleChangeLoading } = useModalContext();

    const onDelete = (id: string) => {
        onOpen({
            modalId: modalIds.DELETE_CONTACT,
            onSubmit: () => {
                handleChangeLoading(true);
                apiWalletService
                    .deleteContact(id)
                    .then(() => {
                        requestSuccessLocale('delete_contact');
                        refetchContacts();
                        navigate(routes.e_transfer_contacts);
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const columns = [
        {
            label: t('name'),
            key: 'firstName',
            render: (_: string, data: TContact) => `${data.firstName} ${data.lastName}`
        },
        {
            label: t('email_address'),
            key: 'email'
        },
        {
            label: t('phone'),
            key: 'phone'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: string, data: TContact) => (
                <div className={styles.table_actions}>
                    <Button leftIcon="edit" onClick={() => handleEdit(data)} />
                    <Button
                        leftIcon="trash"
                        className={styles.delete}
                        onClick={() => onDelete(data.id)}
                    />
                </div>
            )
        }
    ];

    return (
        <>
            <Button variant="primary" size="small" className={styles.new_contact} onClick={onAdd}>
                {t('add_contact_label')}
            </Button>
            <motion.div {...pageAnimation} className={styles.content}>
                <Table
                    columns={columns}
                    rows={contactList}
                    isActions
                    emptyDescription="No contacts yet"
                />
            </motion.div>
        </>
    );
};

export default ContactsTable;
