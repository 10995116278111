import { useProgressBar } from '@hooks/helpers/useProgressBar';
import Info from '@sections/e_transfer/send_money/info';
import Success from '@sections/e_transfer/send_money/success';
import Verification from '@sections/e_transfer/send_money/verification';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { apiAccountService, apiWalletService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useUserContext } from '@hooks/context/useUserContext';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferSendMoneyProgressSteps } from '@const/progress_bar_steps';

const SendMoneySection = () => {
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferSendMoneyProgressSteps
    );
    const formMethods = useForm({});
    const { refetchAccount } = useUserContext();

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            return apiAccountService.sendMoneyETransfer({
                contactId: values.contact,
                amount: Number(values.amount),
                securityQuestion: values.securityQuestion,
                securityAnswer: values.securityAnswer,
                message: values.message
            });
        },
        onSuccess: () => {
            requestSuccessLocale('send_money');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const { data: contactList } = useApiQuery({
        method: () => apiWalletService.getContactList()
    });

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return <Info contactList={contactList || []} onNextStep={nextStep} />;
            case '2':
                return (
                    <Verification
                        contactList={contactList || []}
                        isLoading={isLoading}
                        onSubmit={() => onSubmit(undefined)}
                        onPrevStep={prevStep}
                    />
                );
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.progress_wrapper}>
                <div className={styles.content}>
                    <ProgressBar
                        variant="light"
                        size="large"
                        steps={
                            eTransferSendMoneyProgressSteps.filter(
                                (item) => item !== undefined
                            ) as TProgressItem[]
                        }
                        availableStep={availableStep}
                        currentStep={currentStep}
                        className={styles.progress}
                    />
                </div>
            </div>
            <FormProvider {...formMethods}>{renderContent()}</FormProvider>
        </motion.div>
    );
};

export default SendMoneySection;
