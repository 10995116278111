import React, { useEffect, useState } from 'react';
import EmailSection from '@sections/signup/email';
import PhoneSection from '@sections/signup/phone';
import useSignupContext from '@hooks/context/useSignupContext';
import VerificationPhoneSection from '@sections/signup/verification_phone';
import PasswordSection from '@sections/signup/password';
import PersonalDetailsSection from '@sections/signup/personal_details';
import BillingSection from '@sections/signup/billing';
import CongratsSection from '@sections/signup/congrats';
import AccountTypeSection from '@sections/signup/account_type';
import VerifyEmailSection from '@sections/signup/verification_email';
import ProgressBar from '@components/progress_bar';
import styles from './styles.module.scss';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    signupBusinessStepsValidation,
    signupPersonalStepsValidation
} from '@helpers/validation_objects/signup';
import {
    addressValidationErrors,
    defaultBusinessSignUpData,
    defaultPersonalSignUpData
} from '@const/default_form_data';
import { EAddressType, EUserType } from '@xeppt/xeppt-sdk/types';
import BusinessAddressSection from '@sections/signup/business_address';
import BusinessDetailsSection from '@sections/signup/business_details';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const SignUpLayout = () => {
    const { validationLocale } = useLocales();
    const [isPhoneVerification, setIsPhoneVerification] = useState(false);
    const [addressStep, setAddressStep] = useState(EAddressType.BUSINESS);
    const { steps, currentStep, availableStep, type, onChangeType, changeStep } =
        useSignupContext();
    const [savedStep, setSavedStep] = useState<string | undefined>('1');

    const formMethods = useForm({
        defaultValues: {
            ...(type === EUserType.INDIVIDUAL
                ? defaultPersonalSignUpData
                : defaultBusinessSignUpData)
        },
        resolver: zodResolver(
            type === EUserType.INDIVIDUAL
                ? signupPersonalStepsValidation({
                      currentStep: Number(currentStep?.id || 1) - 1,
                      phoneVerification: isPhoneVerification,
                      messages: {
                          email: validationLocale('email'),
                          phone: validationLocale('phone'),
                          phoneVerifyCode: validationLocale('phoneVerifyCode'),
                          firstName: validationLocale(' phoneVerifyCode'),
                          lastName: validationLocale('lastName'),
                          birthDate: validationLocale('birthDate'),
                          occupancy: validationLocale('occupancy'),
                          password: validationLocale('password'),
                          passwordConfirm: validationLocale('passwordConfirm'),
                          billingAddress: addressValidationErrors(validationLocale)
                      }
                  })
                : signupBusinessStepsValidation({
                      addressType: addressStep,
                      currentStep: Number(currentStep?.id || 1) - 1,
                      phoneVerification: isPhoneVerification,
                      messages: {
                          email: validationLocale('email'),
                          phone: validationLocale('phone'),
                          phoneVerifyCode: validationLocale('phoneVerifyCode'),
                          firstName: validationLocale('firstName'),
                          lastName: validationLocale('lastName'),
                          birthDate: validationLocale('birthDate'),
                          address: addressValidationErrors(validationLocale),
                          password: validationLocale('password'),
                          passwordConfirm: validationLocale('passwordConfirm'),
                          residence: validationLocale('residence'),
                          occupancy: validationLocale('occupancy'),
                          business: {
                              companyName: validationLocale('companyName'),
                              tradingName: validationLocale('tradingName'),
                              registrationNumber: validationLocale('registrationNumber'),
                              category: validationLocale('category'),
                              size: validationLocale('size'),
                              website: validationLocale('website')
                          }
                      }
                  })
        )
    });

    const token = new URLSearchParams(window.location.search).get('token');

    useEffect(() => {
        if (token) {
            changeStep('7');
            formMethods.setValue('code', token);
        }
    }, []);

    useEffect(() => {
        if (availableStep?.id === savedStep) {
            setTimeout(() => {
                formMethods.trigger();
            }, 100);
        } else {
            setSavedStep(currentStep?.id);
        }
    }, [currentStep, availableStep]);

    const renderPersonalSections = () => {
        switch (currentStep?.id) {
            case '1':
                return <AccountTypeSection value={type} onChange={(val) => onChangeType(val)} />;
            case '2':
                return <EmailSection />;
            case '3':
                return !isPhoneVerification ? (
                    <PhoneSection onCodeSent={() => setIsPhoneVerification(true)} />
                ) : (
                    <VerificationPhoneSection onBack={() => setIsPhoneVerification(false)} />
                );
            case '4':
                return <PersonalDetailsSection />;
            case '5':
                return <BillingSection />;
            case '6':
                return <VerifyEmailSection />;
            case '7':
                return <PasswordSection />;
            default:
                return <CongratsSection />;
        }
    };

    const renderBusinessSections = () => {
        switch (currentStep?.id) {
            case '1':
                return <AccountTypeSection value={type} onChange={(val) => onChangeType(val)} />;
            case '2':
                return <EmailSection />;
            case '3':
                return !isPhoneVerification ? (
                    <PhoneSection onCodeSent={() => setIsPhoneVerification(true)} />
                ) : (
                    <VerificationPhoneSection onBack={() => setIsPhoneVerification(false)} />
                );
            case '4':
                return <PersonalDetailsSection />;
            case '5':
                return <BusinessDetailsSection />;
            case '6':
                return (
                    <BusinessAddressSection
                        type={addressStep}
                        onChange={(val) => setAddressStep(val)}
                    />
                );
            case '7':
                return <VerifyEmailSection />;
            case '8':
                return <PasswordSection />;
            default:
                return <CongratsSection />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <FormProvider {...formMethods}>
                {type === EUserType.INDIVIDUAL
                    ? renderPersonalSections()
                    : renderBusinessSections()}
            </FormProvider>
            <ProgressBar
                className={styles.mobile_progress}
                isMini
                steps={steps}
                availableStep={availableStep}
                currentStep={currentStep}
            />
        </motion.div>
    );
};

export default SignUpLayout;
